<template>
  <Text tag="p" size="sm" weight="bold" class="!w-fit md:hidden" v-if="mappedFilters.length > 0">Applied Filters</Text>

  <Stack
    tag="div"
    gap="xs"
    v-if="mappedFilters.length > 0"
    align="center"
    class="flex-wrap"
    data-test-id="plpAppliedFiltersSection"
    data-fs="plpAppliedFiltersSection"
  >
    <div class="hidden md:block">
      <Text tag="p" size="sm" weight="bold" class="!w-fit">Applied Filters</Text>
    </div>

    <Stack
      v-for="(facet, facetIndex) in mappedFilters"
      gap="2xs"
      class="flex-wrap"
      :key="`${facet.id} - ${facetIndex}`"
    >
      <div v-for="(value, index) in facet.values" :key="`${value} - ${index}`" data-test-id="appliedFilter">
        <button
          @click="
            clearFilter(
              facet.id == 'price'
                ? [
                    { id: facet.id, valueId: 'from' },
                    { id: facet.id, valueId: 'to' },
                  ]
                : [{ id: facet.id, value }],
            )
          "
          data-test-id="plpAppliedFilter"
          data-fs="plpAppliedFilter"
        >
          <Tag
            :title="$ft(`${facet.id}`, facet.id)"
            :subtitle="getFilterLabel(facet, value)"
            :data-test-name="$ft(`${facet.id}`, facet.id)"
            :data-test-content="getFilterLabel(facet, value)"
            :data-test-id="
              `appliedFilterName-` + formatTestId($ft(`${facet.id}`, facet.id)) + `-${getFilterLabel(facet, value)}`
            "
          />
        </button>
      </div>
    </Stack>
    <button @click="clearAll" class="absolute md:static top-none right-none" data-test-id="plpClearAllFilters">
      <Tag title="Clear all" variant="clear" />
    </button>
  </Stack>
</template>

<script lang="ts" setup>
import { capitalizeFirstLetter } from "mkm-avengers";
import { productFiltersTranslation } from "~/utils/productUnitTranslation";
const { filters, clear, clearAll } = useFilters();

interface FilterValue {
  from?: number;
  to?: number;
  [key: string]: any;
}

interface MappedFilter {
  id: string;
  values: FilterValue[];
}

const clearFilter = (item: any) => {
  // @ts-ignore
  clear(...item);
};

const filterLabel = (name: any, facetID: any, count = false) => {
  let label = "";

  const availabilityLabels: { [key: number]: string } = {
    1: "Available",
    2: "Enquiry only",
    3: "Out of stock",
    4: "Dropship products",
  };

  label = facetID === "availability_flag" ? availabilityLabels[Number(name)] : capitalizeFirstLetter(name);

  return `${label}${count ? ` (${count})` : ""}`;
};

const checkIsRangeFacet = (value: any[]) => {
  return Array.from(value)[0] === "[";
};

const replacedRangeFacetValue = (value: any) => {
  let updatedFacetName = value.replace("[", "").replace("]", "").replace("TO", "-");

  if (updatedFacetName[0] === "*") {
    updatedFacetName = updatedFacetName.replace("* -", "Less than ");
  } else if (updatedFacetName[updatedFacetName.length - 1] === "*") {
    updatedFacetName = updatedFacetName.replace(" - *", "");
    updatedFacetName = "More than " + updatedFacetName;
  }

  return updatedFacetName;
};

const mappedFilters: ComputedRef<MappedFilter[]> = computed(() => {
  const keys = Object.keys(filters.value);

  return keys
    .flatMap((key): MappedFilter => {
      const values = filters.value[key];

      if (key === "price") {
        if (values.from || values.to) {
          return {
            id: "price",
            values: [{ from: values.from || 0, to: values.to || 99_999 }],
          };
        }

        return { id: "price", values: [] };
      }

      return { id: key, values: Array.isArray(values) ? values : [values] };
    })
    .filter((filter) => filter.values.length > 0);
});

const getFilterLabel = (facet: any, value: any) => {
  if (facet.id !== "price" && facet.id !== "category" && !checkIsRangeFacet(value)) {
    return filterLabel(value, facet.id);
  } else if (facet.id === "price") {
    return `${value.from} - ${value.to}`;
  } else if (facet.id === "category") {
    return value.name;
  } else if (checkIsRangeFacet(value)) {
    return replacedRangeFacetValue(value);
  }
  return "";
};

const $ft = (key: any, fallback: string) => {
  if (productFiltersTranslation(key)) return productFiltersTranslation(key);

  return fallback.replace(/[/_-]/g, " ").replace(/^\w/, (match: string) => match.toUpperCase());
};

const formatTestId = (text: string | undefined): string => {
  if (!text) {
    return "";
  }

  return text
    .split(" ")
    .filter(Boolean)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
</script>
